import React from "react";
import {Grid, Image} from "semantic-ui-react";
import {HeaderTop} from "../Components/HeaderTop";


export const Tech = () => {


  return (
    <div className={"main"} style={{margin: "auto", maxWidth: 800, paddingBottom: '10vw'}}>
      <HeaderTop props={"TECHNOLOGY"}/>

      <p className={'description-about'}>
        Our goal is to deliver exceptional quality products and advance medical education.
        The company's technology focus can be summarized below:
        <ul>
          <li>Collaboration: partner with experts in the medical field</li>
          <li>Evolution: use the latest computer components available</li>
          <li>Quality: utilize best in class commercial grade materials</li>
          <li>Reliability: achieve consistency in software and hardware function</li>
        </ul>
      </p>

      <div style={{marginTop: "5%"}}>
        <Grid container columns={3}>
          <Grid.Column>
            <Image src="/Arthroscope_image.png"/>
          </Grid.Column>
          <Grid.Column>
            <Image src="/Electrocautery_image.png"/>
          </Grid.Column>
          <Grid.Column>
            <Image src="/grasper.png"/>
          </Grid.Column>
        </Grid>
      </div>

      <div className="row  marginNonMobile" style={{backgroundColor: "#f7f7f7", marginTop: '5vw'}}>
        <div className={"columnImageMiddleSize"}>
          <p className={'description-about'} style={{marginTop: '8%'}}>
            Arthroscope that simulates 30 and
            70 degree lens vision. (Patent pending)
          </p>
        </div>
        <div className={"columnTextMiddle"}>
          <img
            src="https://res.cloudinary.com/djagznbnb/image/upload/v1701649635/arthro_tech.jpg"
            alt="arthoscope"
            style={{float: 'left', maxWidth: '100%', height: 'auto', padding: "2%"}}
          />

        </div>
      </div>

      <div className="row  marginNonMobile" style={{marginTop: '5vw'}}>
        <div className={"columnImageMiddleSize"}>
          <img
            src="https://res.cloudinary.com/djagznbnb/image/upload/v1701649760/cartVention.jpg"
            alt="cart"
            style={{float: 'left', maxWidth: '100%', height: 'auto'}}
          />
        </div>
        <div className={"columnTextMiddle"}>
          <p className={'description-about middleText'}>
            Using best in class commercial grade aluminum
            profiles from Vention, the
            simulator assembly is build
            with the same framing used
            by companies like Google,
            Boeing and Universal
            Robotics.

          </p>
          <img src="/ventionLogo.jpg" style={{width: '20%', marginLeft: '2%'}} alt="logo_vention"/>

        </div>
      </div>

      <div className="row  marginNonMobile" style={{backgroundColor: "#f7f7f7", marginTop: '5vw'}}>
        <div className={"columnTextMiddle"}>
          <p className={'description-about tracking'} style={{marginTop: '2%'}}>
            Accurate tracking with no latency, no instrument calibration is needed.
            (Patent pending)
          </p>
        </div>
        <div className={"columnImageSmallSize"}>
          <img
            className={"imgTracking"}
            src="/xyz.png"
            alt="cart"
            style={{float: 'left', maxWidth: '100%', height: 'auto'}}
          />
        </div>
      </div>
      <div>
        <img src="/graphics.jpg" alt="graphics" style={{width: "100%", marginTop: '5vw'}}/>
      </div>


    </div>
  );
};