import React from 'react';
import Menu from "./Components/Menu";

export default function App() {
  return (
    <>
      <Menu/>
    </>
  );
}

