
export function HeaderTop({props}) {
  return <div style={{
    fontFamily: "Montserrat, sans-serif",
    backgroundColor: "#f8f8f8",
  }}>
    <h1 style={headerStyle}>{props}</h1>

  </div>;
}

const headerStyle = {
  fontFamily:
    'Montserrat, sans-serif',
  padding: '1vw',
  borderBottom: '.5px solid lightBlue'
};

