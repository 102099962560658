import React from "react";

export const Footer = () => {
  return (

    <footer className={'footerBottom'}
            style={
              {
                position: 'fixed',
                bottom: 0,
                left: 0,
                height: '2vw',
                backgroundColor: '#f4f4f4',
                textAlign: 'center',
                width: '100%',
                paddingTop: 20,
                paddingBottom: 20,
              }
            }>
      <span>Copyright © VR SURGICAL {new Date().getFullYear()} </span>
    </footer>
  )
}