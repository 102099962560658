import React from "react";
import LabrumVid from '../Videos/websiteVid_LowQ.mp4';
import {Image} from "semantic-ui-react";

export const ProductDescription = () => {


  let buttonStyle = {width: '100%', marginTop: "3.5%", paddingBottom: '3%'};


  return (
    <>
      <div className={'description-home-page'}>
        <div style={{border: 'solid #539ad7 1px', padding: 15, borderRadius: 3}}>
          <p>
            The <span className={"lighter"}>ARTHRO VR SHOULDER </span>
            is an arthroscopic training simulator that mimics the real
            operating room conditions.

            It offers training modules from basic skills (Orientation, Lens Rotation)
            to advanced skills (Labrum Repair). All modules are also available in a
            virtual operating room by wearing an industrial grade VR Headset.
          </p>

          <h1 style={{fontFamily: 'Montserrat, sans-serif'}}>
            List Of Modules
          </h1>

          <ul>
            <li>
              Orientation
            </li>
            <li>
              LooseBody removal
            </li>
            <li>
              Shaving the frayed labrum
            </li>
            <li>
              Biceps tenotomy (scissors)
            </li>
            <li>
              Biceps tenotomy (electrocautery)
            </li>
            <li>
              Subacromial bursectomy
            </li>
            <li>
              Complete labrum repair
            </li>
          </ul>

          <h1 style={{fontFamily: 'Montserrat, sans-serif'}}>
            Modules Metrics<small>*</small>
          </h1>

          <ul>
            <li>Performance</li>
            <ul>
              <li>
                Instrument path length
              </li>
              <li>
                Horizon adjustment
              </li>
              <li>
                Centering view
              </li>
              <li>
                Depth view
              </li>
            </ul>
          </ul>

          <ul>
            <li>Safety</li>
            <ul>
              <li>
                Humerus injury
              </li>
              <li>
                Glenoid injury
              </li>
              <li>
                Correct portal use
              </li>
            </ul>
          </ul>
          <span style={{marginTop: 0}}><i><small><small><small>
            *Metrics in vr room mode are used only in labrum repair. All non vr mode modules are using metrics.
          </small></small></small></i></span>

        </div>
        <video poster={'/placeholderVideo.png'}
               src={LabrumVid}
               style={buttonStyle}
               controls={true}
        ></video>
        <div style={{textAlign: "center", marginBottom: 40}}>
          <Image.Group size='large'>
            <Image src={'/Dr_Taso95.jpg'}/>
            <Image src={'/Dr_Taso64.jpg'}/>
            <Image src={'/Dr_Taso18.jpg'}/>
            <Image src={'/Dr_Taso23.jpg'}/>
            <Image src={'/Dr_Taso107.jpg'}/>
            <Image src={'/Dr_Taso33.jpg'}/>
            <Image src={'/Dr_Taso37.jpg'}/>
            <Image src={'/Dr_Taso53.jpg'}/>
            <Image src={'/Dr_Taso68.jpg'}/>
            <Image src={'/Dr_Taso71.jpg'}/>
            <Image src={'/Dr_Taso84.jpg'}/>
          </Image.Group>
        </div>
      </div>


    </>
  )

}