import React from "react";
import {MainPictures} from '../Components/MainPictures';
import {ProductDescription} from "../Components/ProductDescription";

export const Home = () => {


  return (

    <>
      <MainPictures />
      <ProductDescription  />
    </>
  );
};
