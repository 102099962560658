import React from "react";
import {HeaderBig} from "./HeaderBig";
import trimmedVideo from '../Videos/videoStartLowQ.mp4';


export const MainPictures = () => {


  return <div className={"main"}>
    <video autoPlay muted loop id="myVideo"
           style={{
             width: '100%',
             height: 'auto',
             marginBottom: '-5.5vw',
             marginTop: '-5vw',
             zIndex: 0,
             opacity: ".6",
           }}>
      <source src={trimmedVideo} type="video/mp4"/>
    </video>

    <div className="ui fluid image ">
      <h1 style={{
        position: "absolute",
        width: '50vw',
        bottom: '15.5em',
        fontSize: '3vw',
        left: '40vw',
        fontFamily: 'Montserrat, sans-serif',
        backgroundColor: 'rgb(101,164,234)',
        padding: 10,
        opacity: .6,
        color: "white",
        letterSpacing: '0.01em',

      }}>ARTHRO VR SHOULDER</h1>
      <p style={{
        position: "relative",
        width: '50vw',
        bottom: '9em',
        fontSize: '3vw',
        left: '40vw',
        fontFamily: 'Montserrat, sans-serif',
        backgroundColor: 'rgba(0,0,0,0.97)',
        padding: 10,
        opacity: .55,
        color: "white",
        letterSpacing: '0.01em',

      }}>
        Introducing a new era in arthroscopy simulation
        using virtual reality and passive haptic feedback
      </p>


      {/*<img*/}
      {/*  src={"/Cart_main_image.png"}*/}
      {/*  alt={"medical simulator"}*/}
      {/*/>*/}
      {/*<div className="ui fluid labelTop">
        <p>
          ARTHRO VR SHOULDER
          <p className={"subtitle"}>
            Introducing a new era in arthroscopy simulation
            using virtual reality and passive haptic feedback</p>
        </p>
      </div>*/}

    </div>


    <div>
      <div style={{marginTop: "-10vw", color: "#1a2b3c"}}>

        <HeaderBig text1={'Superior Passive Haptic technology'} text2={'using accurate 3D bone models'}/>
        <div className={"smallerImg"}>
          <img className={"ui image fluid"} src="https://res.cloudinary.com/djagznbnb/image/upload/v1701648863/transparent_model.png" alt="tray"/>
        </div>
      </div>


      <div style={{marginTop: "5vw", color: "black", backgroundColor: "#eaeaea"}}>
        <HeaderBig text1={' VR surgical instruments'} text2={' made of stainless steel, carbon fiber, aluminum'}/>
        <div className={"smallerImg"}>
          <img className={"ui image fluid"} src="https://res.cloudinary.com/djagznbnb/image/upload/v1701648953/tray_render.png" alt="tray"/>
        </div>
      </div>
    </div>


  </div>;
}