import React from "react";
import {HeaderTop} from "../Components/HeaderTop";

export const About = () => {


  return (
    <div className={"main"} style={{margin: "auto", maxWidth: 800, paddingBottom: '0'}}>
      <div style={{
        fontFamily: 'Montserrat, sans-serif',
        backgroundColor: "#f8f8f8",
      }}>
        <HeaderTop props={'ABOUT'}/>

      </div>

      <p className={'description-about'}>
        Our team of engineers, artists and physicians share the same goal, to create
        innovative products that will advance the training and education in the field of
        medicine or surgery using computer simulation systems.
      </p>

      <div className="row row-mobile">
        <div className={"columnImageSmallSize"}>
          <img
            src="https://res.cloudinary.com/djagznbnb/image/upload/v1701648176/anastasios_papadonikolakis-min.png"
            alt="anastasios papadonikolakis"
            style={{float: 'left', maxWidth: 100, height: 'auto'}}
          />
        </div>
        <div className={"columnTextLarge"}>
          <h2>Anastasios Papadonikolakis, MD, PhD </h2>
          <h3>Co-Founder</h3>
          <p> Anastasios has 10 years of experience in the field of arthroscopic surgery,
            PhD in motion capture and 3D tracking. He is advisor and collaborator to our engineers
          </p>
        </div>
      </div>

      <div className="row row-mobile" style={{marginTop: '7%'}}>
        <div className={"columnImageSmallSize"}>
          <img
            src="/pavlos_pic.jpg"
            alt="p papadonikolakis"
            style={{maxWidth: 170, height: 'auto'}}
          />
        </div>

        <div className={"columnTextLarge"}>
          <h2>Pavlos Papadonikolakis, Software Engineer </h2>
          <h3>Co-Founder</h3>
          <p> Pavlos is the chief software engineer responsible for the software development.
            He has also 13 years of experience in manufacturing/machining engineering
          </p>
        </div>
      </div>

      {/*----------------*/}

      <div className="row row-mobile" style={{marginTop: '7%'}}>
        <div className={"columnImageSmallSize"}>
          <img
            src="/nik_paschos.jpeg"
            alt="nik paschos"
            style={{maxWidth: 170, height: 'auto'}}
          />
        </div>

        <div className={"columnTextLarge"}>
          <h2>Dr. Nikolaos Paschos, MD, PhD </h2>
          <h3>Advisory Board</h3>
          <p> As the Director of the Pediatric Orthopedic Research at MassGeneral Hospital for Children, Dr. Paschos has not
            only pioneered novel surgical techniques and groundbreaking orthopedic devices, but he also serves on the
            esteemed editorial board of the Arthroscopy Journal. We're particularly enthusiastic about his current
            collaboration with us on the knee arthroscopy simulator. Here's to innovative strides and redefining the
            future of surgical training!
          </p>
        </div>
      </div>

      <div className="row row-mobile" style={{marginTop: '7%'}}>
        <div className={"columnImageSmallSize"}>
          <img
            src="/Dragan.jpeg"
            alt="Dragan Beljan"
            style={{maxWidth: 170, height: 'auto'}}
          />
        </div>

        <div className={"columnTextLarge"}>
          <h2>Dragan Beljan, Design Engineer </h2>
          <h3>Collaborator (Solidworks AutoCAD ArtCAM & Keyshot)</h3>
          <p> Dragan has more than 12 years of experience in 3D CAD design. He is a holder of a Solidworks CSWP. The
            main focus is on part modeling, assembly, drafting, basic FEA, rendering, and animation. 6 years of
            professional company experience (sheet metal and aerospace). Several years in the world of freelancing and
            successfully finalized projects on five continents. Software specialist, Solidworks, AutoCAD, Artcam
            (G-Code), KeyShot, and Microsoft Office.
          </p>
        </div>
      </div>


      {/*contact info -----------------------------------------------*/}
      <div style={{backgroundColor: "#f8f8f8", marginTop: "5%"}}>
       <HeaderTop props={'Contact info'}/>
        {/*usa -----------------*/}
        <div className="row">
          <div className={"columnAddress"}>
            <h2>USA:</h2>
          </div>

          <div className={"columnTextLarge"} style={{marginTop: '1vw'}}>
            <span style={{textDecoration: 'underline'}}>Address:</span><br/>
            <span>380 Knollwood ST STE H #337, Winston Salem, NC 27103</span><br/>
            <span style={{textDecoration: 'underline'}}>Email:</span><br/>
            <span> <a href="mailto:info@vr-surgical.com">info@vr-surgical.com</a></span><br/>
            <span style={{textDecoration: 'underline'}}>Tel:</span><br/>
            <a href="tel:0017606970175">001 - (760) 697-0175</a><br/>
            <a href="tel:0013363910051">001 - (336) 391-0051</a><br/>
            <span style={{textDecoration: 'underline'}}>Technical support:</span><br/>
            <span> <a href="mailto:tech@vr-surgical.com">tech@vr-surgical.com</a></span><br/>
          </div>

        </div>


        {/*europe--------------*/}
        <div className="row">
          <div className={"columnAddress"}>
            <h2>EUROPE:</h2>
          </div>

          <div className={"columnTextLarge"} style={{marginTop: '1vw'}}>
            <span style={{}}>Distributor: Stavros Toumpoulis, DDS</span><br/><br/>
            <span style={{textDecoration: 'underline'}}>Address:</span><br/>
            <span>Trion Navarchon 32, Patra, 26222, Greece</span><br/>
            <span style={{textDecoration: 'underline'}}>Email:</span><br/>
            {/*<span></span><br/>*/}
            {/*<span></span><br/>*/}
            <span style={{textDecoration: 'underline'}}>Tel:</span><br/>

          </div>

        </div>

      </div>
      <div style={{padding: "7% ", color: "gray"}} className={"trademarks-text-bottom"}>
        <p>All other trademarks mentioned herein, including company names, product names, service names and logos, are
          the
          property of their respective owners and their use herein does not indicate an affiliation with, association
          with, or endorsement of or by VR Surgical.
          Screen images contained herein are simulated. VR surgical shall not be liable for technical or editorial
          errors
          or omissions contained herein, nor for incidental or consequential damages resulting from furnishing this
          material. The information is provided ​“‍as is” without warranty of any kind and is subject to change without
          notice. VR surgical, Inc also reserves the right to revise the content of this document at any time without
          prior notice.
          No part of this document may be reproduced or transmitted in any form or by any means, electronic or
          mechanical,
          including photocopying, recording or storing in a retrieval system, or translated into any language in any
          form
          without prior written permission from VR Surgical.</p>
      </div>

    </div>
  );
};