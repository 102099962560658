import React, {Component} from 'react';
import {Icon, Menu} from 'semantic-ui-react';
import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';
import {Home} from "../Pages/Home";
import {About} from "../Pages/About";
import {Tech} from "../Pages/Tech";
import {BuyingOptions} from "../Pages/BuyingOptions";
import {Footer} from "./Footer";
import {Dropdown} from "semantic-ui-react";

const DividerHorizontalLeft = () => (
  <div style={{height: 35, marginTop: 4, marginLeft: '1.2%', borderLeft: 'solid 2px lightGrey'}}/>
)
const DividerHorizontalRight = () => (
  <div style={{height: 35, marginTop: 4, marginLeft: '1.7%', borderLeft: 'solid 2px lightGrey'}}/>
)

export default class MenuTop extends Component {

  render() {

    return (
      <div>

        <Router>

          <Menu pointing size={'large'} fixed={"top"} color={"blue"} icon={"labeled"}>

            <h3 className={"font-face-abnes logo"} style={{margin: 20}}>
              <Link to="/">
                VR SURGICAL<sup style={{fontFamily: 'sans-serif'}}>
                <small>TM</small></sup>
              </Link>
            </h3>

            {DividerHorizontalLeft()}

            <Dropdown style={{color: '#538ee7', marginLeft: '2%', marginTop: 10, fontSize: 30}}
                      icon={'caret down'}
                      pointing={'top right'}>
              <Dropdown.Menu style={{fontSize: 20}}>
                <Dropdown.Item as={Link} to='/about'> <i className="info icon "></i>About </Dropdown.Item>
                <Dropdown.Item as={Link} to='/tech'> <i className="settings icon "></i>Tech</Dropdown.Item>
                <Dropdown.Item as={Link} to='/sales'><i className="tag icon "></i>Sales</Dropdown.Item>
                <Dropdown.Item as={Link} to='/'> <i className="home icon "></i>Home</Dropdown.Item>
                <Dropdown.Divider/>
                <div style={{padding:10}}>
                  <a href='https://www.facebook.com/profile.php?id=100092543558906'> <Icon name={'facebook square'}
                                                                                           size={'large'}/> </a>
                  <a href='https://www.linkedin.com/company/vr-surgical'> <Icon name={'linkedin square'}
                                                                                size={'large'}/> </a>
                  <a href='https://www.youtube.com/channel/UCKlw06kCTY6DkQwMk_IX7kg'> <Icon name={'youtube square'}
                                                                                            color={'red'}
                                                                                            size={'large'}/> </a>
                </div>


              </Dropdown.Menu>
            </Dropdown>
            {DividerHorizontalRight()}
          </Menu>

          <Routes>
            <Route path="/about" element={<About/>}/>
            <Route path="/" element={<Home/>}/>
            <Route path="/tech" element={<Tech/>}/>
            <Route path="/sales" element={<BuyingOptions/>}/>{/* using the previous path but now for sales page */}
            <Route path="/options-buy" element={<BuyingOptions/>}/>
          </Routes>

          <Footer/>
        </Router>
      </div>
    );
  }
}
