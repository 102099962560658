import React from "react";

export const HeaderBig = ({text1,text2}) => {

  let header = {
    textAlign: "center",
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '5vw',
    fontWeight: 'bold',
    paddingTop: 12

  };

  return (
    <p style={header}>
      {text1}
      <br/>
      <span className={"images-description"}>{text2}</span>
    </p>)
}

