import React from "react";
import {Button, Card, Divider, Header, Item, List, Popup} from "semantic-ui-react";
import {HeaderTop} from "../Components/HeaderTop";

export const BuyingOptions = () => {

  return (

    <div className={"main paddingBottom"} style={{margin: "auto", maxWidth: 800}}>
      <div style={{
        fontFamily: 'Montserrat, sans-serif',
        backgroundColor: "#f8f8f8"
      }}>
        <HeaderTop props={'Buy or Lease'}/>

        <Header as='h2' textAlign='center'
                style={{
                  textAlign: 'center', fontFamily: 'Montserrat, sans-serif', marginTop: 12,
                }}>
          BUY
          <Header.Subheader>
            <b>Choose between the following options</b>
          </Header.Subheader>
        </Header>

        <Divider style={{maxWidth: 300, margin: 'auto'}}/>

        <Item.Group>
          <Item>
            <List>
              <List.Item>
                <span style={{fontFamily: 'Montserrat, sans-serif',padding: 2,color:"white",backgroundColor:'rgb(101,164,234)'}}>Monitor and VR Mode</span>
                <Item.Image size='medium' style={{marginLeft: 5, marginRight: 30, maxWidth: 250}}
                            src='https://res.cloudinary.com/djagznbnb/image/upload/v1701649139/Cart_main_image.png'/>
                <img alt={'cart no vr'} src={'/icon-vr.png'} width={50}
                     style={{
                       position: 'relative',
                       left: 135,
                       top: -108
                     }
                     }/>
              </List.Item>
              <List.Item>
                <span style={{fontFamily: 'Montserrat, sans-serif',padding: 2,color:"white",
                  backgroundColor:'rgb(101,164,234)'}}>Monitor Mode</span>
                <Item.Image size='medium' style={{marginLeft: 5}} src='https://res.cloudinary.com/djagznbnb/image/upload/v1701649358/cart_simple2.png'/>
              </List.Item>
            </List>

            <Item.Content style={{marginLeft: 5}}>
              <Item.Header><span
                style={{fontFamily: 'Montserrat, sans-serif'}}>Arthro VR Shoulder</span></Item.Header>
              <Item.Description>
                <Item.Content>
                  <Item.Meta>
                    <span><b>List Of Modules</b></span>
                  </Item.Meta>
                  <Item>
                    <ul>
                      <li>
                        Orientation
                      </li>
                      <li>
                        LooseBody removal
                      </li>
                      <li>
                        Shaving the frayed labrum
                      </li>
                      <li>
                        Biceps tenotomy (scissors)
                      </li>
                      <li>
                        Biceps tenotomy (electrocautery)
                      </li>
                      <li>
                        Subacromial bursectomy
                      </li>
                      <li>
                        Complete Labrum Repair(available only in VR mode)
                      </li>
                    </ul>
                  </Item>
                  <Card.Meta>
                    <span><b>Metrics</b></span>
                  </Card.Meta>
                  <Card.Description>
                    <ul>
                      <li>Performance</li>
                      <ul>
                        <li>
                          Instrument path length
                        </li>
                        <li>
                          Horizon adjustment
                        </li>
                        <li>
                          Centering view
                        </li>
                        <li>
                          Depth view
                        </li>
                      </ul>
                    </ul>
                    <ul>
                      <li>Safety</li>
                      <ul>
                        <li>
                          Humerus injury
                        </li>
                        <li>
                          Glenoid injury
                        </li>
                        <li>
                          Correct portal use
                        </li>
                      </ul>
                    </ul>
                    <ul>
                      <li>Progress</li>
                      <ul>
                        <li>User progress over period of time</li>
                      </ul>
                    </ul>
                  </Card.Description>
                </Item.Content>
              </Item.Description>

              <b>Additional Details</b>
              <Item.Description>
                <li>Adjustable shoulder model for lateral or beach chair position and height</li>
                <li>Customizable hardware (pc, monitor, cart, electromagnetic, or optical tracking)</li>
                <li>Choose any number of modules from the above List of Modules</li>
              </Item.Description>
              <Card.Content extra style={{marginTop: 30}}>
                <Popup
                  trigger={
                    <Button color='blue' href={'mailto:info@vr-surgical.com'}>
                      Request A Quote
                    </Button>}
                  content={'Please include in your info: company or institution name'}
                  position={'top left'}
                />
              </Card.Content>
            </Item.Content>
          </Item>

        </Item.Group>


        <Divider style={{marginTop: 30}}/>

        <Header as='h2' textAlign='center'
                style={{
                  textAlign: 'center', fontFamily: 'Montserrat, sans-serif', marginTop: 12,

                }}>
          LEASE
        </Header>

        <p style={{margin: 10}}>
          You can rent (per month) one of the two above options by providing a security deposit and the cost of
          transportation. If you decide to buy the simulator while in rent, any rent amount paid will be subtracted
          from the buying price.
        </p>
        <Card.Content extra style={{margin: 10, paddingBottom: 20}}>
          <Popup
            trigger={
              <Button color='blue' href={'mailto:info@vr-surgical.com'}>
                Request A Quote
              </Button>}
            content={'Please include in your info: company or institution name'}
            position={'top left'}
          />

        </Card.Content>

      </div>
    </div>


  )
}